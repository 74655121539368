.set_goals_main_box {
  width: 100%;
  height: 100%;
  margin-top: 16px;
  display: flex;
  gap: 16px;
}
.set_goals_column_box {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 50%;
  height: 100%;
}
.set_goals_template_box {
  border-radius: 8px;
  background-color: #1f1f1f;
  padding: 16px;
}
.set_goals_single_row_box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}
.set_goals_single_row_title {
  display: flex;
  align-items: center;
}
.set_goals_single_row_dot {
  width: 4px;
  height: 4px;
  border-radius: 25px;
  background-color: #8c8c8c;
  margin-right: 8px;
}
