.single_line_chart_box {
  background-color: #1f1f1f;
  border-radius: 8px;
  font-family: Montserrat;
  padding: 16px;
  flex: 1;
  max-width: 25%;

  @media only screen and (max-width: 1920px) {
    max-height: 250px;
  }
}

.single_line_chart_and_value {
  display: flex;
  margin-top: -10px;
  @media only screen and (min-width: 1921px) {
    display: block;
    text-align: center;
  }
}

.single_line_chart_value {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -10px;
}

.chart_title_box {
  display: flex;
  align-items: center;
  gap: 8px;
}

.chart_title {
  margin: 0;
  font-weight: 600;
  color: #f1f1f1;
}

.chart_subtitle {
  display:flex;
  align-items: center;
  margin: 0;
  color: #8c8c8c;
  font-size: 0.725rem;
}

.chart_dot {
  width: 8px;
  height: 8px;
  border-radius: 25px;
}

.chart_24px {
  color: #f1f1f1;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  text-align: center;
}

.chart_32px {
  color: #f1f1f1;
  font-size: 32px;
  font-weight: 600;
  margin: 0;
}

.half_donut_box {
  background-color: #1f1f1f;
  border-radius: 8px;
  padding: 16px;
  flex: 1;
  max-width: 25%;

  @media only screen and (max-width: 1920px) {
    max-height: 250px;
  }
}

.half_donut_label_box {
  position: absolute;
  color: red;
  bottom: 46%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.chart_flex_box {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.half_donut_legend_box {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  bottom: 32%;
  gap: 16px;
}

.white_14px {
  font-size: 14px;
  color: #f1f1f1;
  margin: 0;
}

.half_donut_legend_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.outstanding_fees_box {
  background-color: #1f1f1f;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  max-width: 25%;
}

.outstanding_stat_box {
  width: 100%;
  display: flex;
  background-color: #444;
  border-radius: 8px;
}

.outstanindig_red_stat {
  background: linear-gradient(90deg, #ff8ea2, #e82346);
  border-radius: 8px;
  height: 13px;
}

.outstanding_fees_percentage {
  color: #ff8da1;
  font-weight: 600;
  margin: 0;
}

.outstanding_fees_legend_box {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.statistics_template_box {
  background-color: #1f1f1f;
  border-radius: 8px;
  padding: 16px;
}

.multiple_area_header_box {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.multiple_area_flex_box {
  display: flex;
  justify-content: space-between;
  @media only screen and (min-width: 1921px) {
    display: block;
  }
}

.multiple_area_legend_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.multiple_area_legend_box_ports {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;

  @media only screen and (min-width: 1921px) {
    flex-direction: row;
    justify-content: space-around;
  }
}

.bussiest_port_main_box {
  display: flex;
  justify-content: space-between;
}

.gauge_chart_div {
  background-color: #1f1f1f;
  padding: 16px;
  border-radius: 8px;
  width: 33%;

  #apexcharts-radialbarTrack-0 {
    stroke-dasharray: 4;
  }

  .apexcharts-datalabels-group {
    display: none;
  }
}

.radial_bar_box {
  background-color: #1f1f1f;
  border-radius: 8px;
  height: 100%;
  padding: 16px;
}

.radial_bar_legend_item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.dark_grey_14px {
  margin: 0;
  color: #8c8c8c;
  font-size: 14px;
}

.white_14px_bold {
  margin: 0;
  color: #f1f1f1;
  font-size: 14px;
  font-weight: 600;
  max-width: 180px;
}