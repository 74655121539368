.history_main_div {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-width: 1200px;
  overflow-x: scroll;
}
.history_header_div {
  background-color: #fafafa;
  width: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  margin-bottom: 8px;
}
.history_header_title {
  margin: 0;
  color: #1f1f1f;
  font-weight: 600;
}
.history_tables_div {
  display: flex;
  justify-content: center;
  overflow: hidden;
  flex-grow: 1;
}

.history_tables_tranisition {
  flex-grow: 1; /* Add this line */
  height: 100%;
  transition: all 0.3s ease;
  overflow: hidden; /* Transition effect */
  background-color: #fafafa;
  padding: 0px 16px 16px 16px;
}

.left {
  width: 0%;
}

.leftVisible {
  width: 100%;
  height: 100%;
}

.right {
  width: 0px;
}

.rightVisible {
  width: 100%;
  height: 100%;
}
.history_table_main {
  width: 100%;
  transition: all 0.3s ease;
}
.history_row {
  height: 58px;
  border-bottom: 1px solid #bdbdbd66;
}

.right {
  text-align: right;
}
.left {
  text-align: left;
}
.center {
  text-align: center;
}
.orange {
  color: #ee4a26;
}
.dark_grey {
  color: #8c8c8c;
}
.w600 {
  font-weight: 600;
}
.dnd_width {
  width: 17%;
}
.table_footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}
.offset_width {
  width: 12.5%;
}

.offset_status_cell {
  border-radius: 8px;
  padding: 8px;
  width: 90px;
  margin: auto;
}
.green_s {
  background-color: #51da8833;
  color: #51da88;
}
.orange_s {
  background-color: #f5af4533;
  color: #f5af45;
}
.history_no_content_div {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ee4a26;
  font-weight: 600;
  background-color: transparent;
}
