.customer_edit_button {
  color: #51da88;
  background-color: #51da881a;
  border: none;
  border-radius: 8px;
  padding: 6px 12px;
  font-weight: 600;
  font-size: 14px;
}
.green {
  color: #51da88;
  background-color: #51da881a;
}
.red {
  color: #ee4a26;
  background-color: #ee4a261a;
}

.active_button {
  border: none;
  transition: 0.2s all ease-in-out;
  border-radius: 8px;
}
.active_button:hover {
  background-color: #fcdbd44a;
}
