.alert_table_row {
  width: 98%;
  background-color: #1f1f1f;
  border-radius: 8px;
  margin-bottom: 16px;
  position: relative;
  display: flex;
  cursor: pointer;
}
.alert_table_row:hover {
  background-color: #090909;
}
.alert_table_row_dark {
  width: 98%;
  background-color: #090909;
  border-radius: 8px;
  margin-bottom: 16px;
  position: relative;
  display: flex;
  cursor: pointer;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    height: 45px;
  }
}
.alert_dot {
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 25px;
  background-color: #ff00b8;
  top: 0;
  right: 0;
  transform: translate(10px, -10px);
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    width: 11px;
    height: 11px;
  }
}
.my_input:checked {
  background-color: #ff00b8;
}
.container_id_alert {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  padding-left: 8px;
  width: 260px;
  color: #f1f1f1;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.container_id_alert_payment {
  margin: 0;
  font-weight: 600;
  padding-left: 8px;
  width: 23%;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    width: 40%;
    height: 45px;
  }
}
.no_content_div {
  width: 100%;
  height: 93.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1f1f1f;
  border-radius: 8px;
  flex-direction: column;
}
.no_content_dark_grey {
  color: #8c8c8c;
  font-weight: 600;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 11px;
  }
}
.no_content_purple {
  color: #ff00b8;
  font-weight: 600;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 11px;
  }
}

.facility_name_hover {
  display: none;
  position: absolute;
  z-index: 10;
}
.facility_name:hover + .facility_name_hover {
  display: block;
  position: absolute;
  background-color: #1f1f1f;
  border-radius: 8px;
}
