.carttable_main_div {
  width: 100%;
  padding: 16px;
  height: 100%;
}
.carttable_header {
  width: 100%;
  display: flex;
  margin-bottom: 16px;
}
.carttable_row {
  height: 74px;
  border-top: 1px solid #8c8c8c66;
  display: flex;
}
.quantity_button {
  all: unset;
  width: 42px;
  height: 42px;

  display: flex;
  justify-content: center;
  align-items: center;
  color: #41baee;
  background-color: #1f1f1f;
  background-attachment: fixed;
  border-radius: 8px;
  font-size: 32px;
}
.quantity_button:disabled {
  opacity: 0.4;
}
.quantity_value {
  width: 42px;
  height: 42px;

  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: #f1f1f1;
  background-color: #1f1f1f;
  background-attachment: fixed;
  border-radius: 8px;
}
