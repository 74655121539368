.main_div {
  display: flex;
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
  height: 0;
  .first {
    width: 50%;
    height: 100%;
    border-right: 1px solid #8c8c8c;
    padding: 16px;
  }
}
.display_div_first {
  width: 50%;
  height: 100%;
  border-right: 1px solid #8c8c8c;
  padding: 16px;
}
.display_div_second {
  width: 50%;
  height: 100%;
  padding: 16px;
}
.column_title {
  color: #f1f1f1;
  font-weight: 600;
  font-size: 14px;
}
.column_row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.column_row_name {
  margin: 0;
  color: #8c8c8c;
  font-size: 14px;
}
.column_row_number {
  color: #f1f1f1;
  font-size: 14px;
}
.no_data_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f1f1f1;
  font-size: 14px;
}
