.personal_div {
  width: 33%;
  background: white;
  display: flex;
  border-radius: 8px;
}
.full_name_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;
  margin-top: 5px;
}

.contact_div {
  width: 27%;
  background: white;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  border-radius: 8px;
}

.company_div {
  width: 35%;
  background: white;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  border-radius: 8px;
}
