.template_container {
  border-radius: 8px;
  background-color: #1f1f1f;
  width: 100%;
  padding: 16px;
}
.white_text {
  font-size: 14px;
  color: #fafafa;
  font-weight: 400;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 10px;
  }
}
.flex_box_space_between {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.dark_grey_text {
  font-size: 14px;
  color: #8c8c8c;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 10px;
  }
}
