.flex_shrink_box {
  flex: 1 0 400px;
  box-sizing: border-box;
}
.radar_chart {
  display: flex;
  justify-content: center;
  .apexcharts-radar-series {
    line {
      stroke-dasharray: 5;
    }
  }
}

.finish_icon {
  right: 3%;
  top: 50%;
  transform: translateY(-50%);
}
.timeline_graph {
  .apexcharts-tooltip {
    background: #313131;
    border: none;
    padding: 16px;
    position:relative;
    width:340px;
    
  }
}

