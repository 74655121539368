.notificationBar {
  display: flex;
}
.notification_button {
  width: 42px;
  height: 42px;
  background-color: transparent;
  border: none;
  margin-left: 16px;
  border-radius: 8px;
  transition: 0.3s all ease-in-out;
  position: relative;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    height: 30px;
    width: 30px;
  }
}
.notification_button:hover {
  background-color: #343434;
}
.notification_button:focus {
  outline: none;
}
