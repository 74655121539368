.offset_main_box {
  width: 100%;
  padding-top: 16px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 16px;
}
.offset_card {
  background-color: #1f1f1f;
  padding: 16px;
  border-radius: 8px;
}
.offset_header_div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  position: relative;
}
.offset_header_title {
  color: #f1f1f1;
  font-weight: 600;
  margin: 0;
}
.offset_header_title_hover {
  display: none;
}
.offset_header_title:hover + .offset_header_title_hover {
  display: block;
  position: absolute;
  top: 30px;
  right: 0;
  background-color: #1f1f1f;
  border-radius: 8px;
}

.offset_dark_grey_400 {
  color: #8c8c8c;
  margin: 0;
}
.link_paragraph {
  margin: 0;
  color: #03b9f2;
  cursor: pointer;
}
.value_div {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.offset_purple_600 {
  margin: 0;
  color: #ff00b8;
  font-weight: 600;
}
.offset_white_400 {
  margin: 0;
  color: #f1f1f1;
}
.offset_white_600 {
  color: #f1f1f1;
  margin: 0;
  font-weight: 600;
}
.offset_purple_button {
  background-color: #ff00b8;
  border-radius: 8px;
  flex-grow: 1;
  border: 0;
  color: #f1f1f1;
  font-weight: 600;
  padding: 11px;
}

.offset_project_detail_box {
  width: 100%;
  display: flex;
  gap: 16px;
  margin-top: 16px;
}
.offset_project_detail_left_box {
  width: 50%;
  background-color: #1f1f1f;
  border-radius: 8px;
  overflow: auto;
}
.offset_project_detail_rigth_box {
  border-radius: 8px;
  width: 50%;
}
.offset_project_detail_pill_box {
  width: 100%;
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
}

.offset_project_detail_pill {
  border-radius: 8px;
  display: flex;
  padding: 12px 8px;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: auto;
}

.offset_add_to_cart_div {
  display: flex;
  padding: 16px;
  background-color: #1f1f1f;
  border-radius: 8px;
  justify-content: space-between;
  gap: 30px;
  margin-bottom: 16px;
}
.offset_map_box {
  background-color: #1f1f1f;
  border-radius: 8px;
  width: 100%;
  padding: 16px;
  margin-bottom: 24px;
}
.offset_other_projects_div {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
.offset_other_projects_flex_div {
  display: flex;
  gap: 16px;
  width: 100%;
}
.offset_other_projects_card {
  background-color: #1f1f1f;
  border-radius: 8px;
  padding: 16px;
  width: 32%;
}
.offset_other_projects_card_title {
  color: #f1f1f1;
  margin: 0;
  margin-bottom: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.offset_badges_div {
  width: 100%;
}
