.calendar {
  width: 100%;
  padding: 8px;
  text-align: center;
  border-collapse: collapse;
  th,
  td {
    padding: 6px;
    font-size: 14px;
    width: 14%;
  }
}
.date_cell {
  border: 1px solid reds;
}
.rigth_border_cell {
  border-right: 1px solid #8c8c8c;
}
.months_header {
  margin: 0;
  font-weight: 600;
  margin-bottom: 16px;
}
