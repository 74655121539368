.header {
  display: flex;
  justify-content: space-between;
}
.vis_main_container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.body {
  display: flex;
  margin-top: 12px;
  flex-grow: 1;
}

.container_filters {
  display: flex;
}

.container_statistics {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  div:first-of-type {
    // margin-top: 0;
    @media only screen and (max-width: 1400px) and (min-width: 1280px) {
      height: auto;
    }
  }

  .first {
    // min-height: 283px;
    height: auto;

    .container_statistics_title {
      margin-bottom: 0;
    }

    .apexcharts-radialbar-area {
      stroke-width: 7px;
    }
  }

  .second {
    // min-height: 200px;
    height: auto;
    .container_statistics_title {
      margin-bottom: 0;
    }
  }

  .third {
    // min-height: 315px;
    flex-grow: 1;
    margin-bottom: 0;
    .container_statistics_title {
      margin-bottom: 0;
    }
    @media only screen and (max-height: 900px) and (min-height: 600px) {
      min-height: 0;
    }
  }
}

.container_statistics_div {
  display: flex;
  margin: 0px 0px 16px 0px;
  background-color: #1f1f1f;
  width: 100%;
  padding: 16px;
  border-radius: 5px;
  flex-direction: column;
}

.container_statistics_title {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #fafafa;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 11px;
  }
}

.container_statistics_helper {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #8c8c8c;
}

.div-switch-title {
  display: flex;
  width: 100%;
}

.container_statistics_graph {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  @media only screen and (min-width: 2000px) {
    flex-direction: column;
    align-items: center;
  }
}

.container_statistics .apexcharts-text {
  fill: white;
  font-family: "Montserrat" !important;
  font-weight: 600 !important;
}

.container_statistics .apexcharts-legend-text {
  color: white !important;
  font-family: "Montserrat" !important;
  font-weight: 600 !important;
}

.container_map {
  height: 100px;
  width: 70%;
  transition: 0.3s all ease-in-out;
}
.watch_list_map {
  height: 800px;
  width: 50%;
}

.gauge-chart-div .apexcharts-datalabel-label {
  display: none;
}

.purple_button {
  background-color: #ff00b8;
  color: #f1f1f1;
  padding: 12px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  border: none;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 10px;
    height: 30px;
    padding: 8px;
  }
}
.purple_button:focus {
  border: none;
}
.link_button {
  color: #51b1da;
  font-size: 14px;
  font-weight: 600;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 16px;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 10px;
  }
}
.link_button:focus {
  border: none;
  outline: none;
}
.white_text {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  color: #f1f1f1;
}
.transparent_button {
  background-color: transparent;
  border: none;
}
.transparent_button:focus {
  border: none;
  outline: none;
}
