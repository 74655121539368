.google-visualization-tooltip {
  width: 150px;
  background-color: transparent !important;
  position: absolute;
  font-size: 14px;
  font-family: Montserrat !important;
  margin: 0 !important;
  border: 0 !important;
  border-color: transparent;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
  @media (max-width: 1919px) {
    height: 36%;
    width: 0;
  }

}
.google-visualization-tooltip-item-list {
  list-style-type: none;
  margin: 0 !important;
  padding: 0 !important;
}

.google-visualization-tooltip-item:first-child {
  margin: 0 !important;
  display: none;
}
.google-visualization-tooltip-item {
  margin: 0 !important;
  padding: 0 !important;
}

.tooltip_container_left{
  border: 1px solid #8c8c8c;
  position: absolute;
  transform: translateY(-35%);
  color: white;
  font-size: 10px;
  font-weight: 600;
  border-radius: 8px;
  font-family: montserrat;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 12;
  white-space: nowrap; 
  text-overflow: ellipsis;
  
  @media (max-width: 1919px) {
    left: 120px;
    top: 50%;
    transform: translateY(-60%);
  }

  &::before {
        content: '';
        position: absolute;
        bottom: 0;
        top: 50%; 
        left:0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 12px 12px 12px 0;
        border-color:  transparent #8c8c8c transparent transparent;
        transform: translateX(-100%) translateY(-50%);
        z-index: 1;
      }
    
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        top: 50%;
        left: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 10px 10px 0;
        border-color:  transparent #313131 transparent transparent;
        transform: translateX(-100%) translateY(-50%);
        z-index: 1;
      }
}

.tooltip_container_right{
  border: 1px solid #8c8c8c;
  background-color: #1f1f1f;
  position: absolute;
  transform: translateY(-35%);
  color: white;
  font-size: 10px;
  font-weight: 600;
  border-radius: 8px;
  font-family: montserrat;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 12;
  white-space: nowrap; 
  text-overflow: ellipsis;

  @media (max-width: 1919px) {
    top: 50%;
    left: -50px;
    transform: translateY(-60%);
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    top: 50%;
    right: 0%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 0 12px 12px ;
    border-color:  transparent  transparent transparent #8c8c8c;
    transform: translateX(100%) translateY(-50%);
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    top: 50%;
    right: 0%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 10px 10px ;
    border-color:  transparent  transparent transparent #313131;
    transform: translateX(100%) translateY(-50%);
    z-index: 1;
  }
}

