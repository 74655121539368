.esgFiltersDiv {
  display: flex;
  justify-content: space-between;
}

.esgFilters {
  display: flex;
}

.renewableAndProcurement {
  width: 50%;
  margin-left: 0.8rem;
  height: "100%";
}

.stateEmmisions {
  background-color: #1f1f1f;
  padding: 16px;
  border-radius: 0.5rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.stateEmmisionsTotalEmission {
  background-color: #1f1f1f;
  padding: 16px;
  border-radius: 0.5rem;
}

.stateEmmisionsHeader {
  display: flex;
  justify-content: space-between;
}

.stateEmmisionsDetails {
  display: flex;
  justify-content: space-between;
}

.stateEmmisionsDetailsRow {
  display: flex;
  @media only screen and (min-height: 1200px) {
    flex-grow: 1;
  }
}

.emmissionsShipment {
  background-color: #1f1f1f;
  padding: 0.8rem;
  border-radius: 0.5rem;
}

.emmissionsShipmentGraf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  @media only screen and (min-height: 1200px) {
    flex-direction: column-reverse;
  }
}

.lineProcents {
  display: flex;
  gap: 8px;
  align-items: center;
}

.lineProcentsLabel {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  align-items: center;
}

.lineProcentsLabelBottom {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: center;
  margin-bottom: 8px;
}

.co2Label {
  padding-top: 15px;
  color: #fafafa;
  font-size: 2rem;
  font-weight: 600;
  margin: 0;
}

.sustainableLabel {
  font-size: 1rem;
  font-weight: 600;
  color: #f1f1f1;
  margin: 0;
}

.co2Value {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  color: #f1f1f1;
}
.ratingDivMain {
  background-color: #1f1f1f;
  padding: 0.8rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  @media only screen and (min-height: 1200px) {
    flex-grow: 1;
  }
}

.ratingDiv {
  @media only screen and (min-height: 1200px) {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
}

.emmissionsPortRail {
  background-color: #1f1f1f;
  padding: 0.8rem;
  border-radius: 0.5rem;
}

.emmissionsPortRailTextFlex {
  display: flex;
  margin-top: 8px;
  justify-content: center;
  align-items: center;
  width: 90%;
  gap: 16px;
  margin-left: 5%;
}

.emmissionsPortRailText {
  display: flex;
  justify-content: center;
  color: #f1f1f1;
  font-weight: 400;
  gap: 4px;
}

.procurementChartLegend {
  display: flex;
  color: #f1f1f1;
  font-weight: 400;
}
.ratingRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  @media only screen and (min-height: 1200px) {
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
  }
}

.ratingRowText {
  color: #fafafa;
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  margin-bottom: 8px;
  // padding-top: 1rem;
}

.plantDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
}
.ratingValueScore {
  display: flex;
  align-items: center;
}
.ratingValue {
  display: flex;
  align-items: center;
  @media only screen and (min-height: 1200px) {
    flex-direction: column;
  }
}

.ratedDiv {
  margin-left: 0.8rem;
  @media only screen and (min-height: 1200px) {
    display: flex;
    gap: 4px;
  }
}

.ratedText {
  margin: 0;
  color: #8c8c8c;
  font-size: 0.875rem;
}

.plantImg {
  height: 24px;
  @media only screen and (min-height: 1200px) {
    height: 32px;
  }
}

.emmissionsPortRail .apexcharts-text {
  fill: white;
  font-family: "Montserrat" !important;
  font-weight: 600 !important;
}

.procurementDiv {
  background-color: #1f1f1f;
  padding: 0.8rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  @media only screen and (min-height: 1200px) {
    flex-grow: 1;
  }
}

.renewableGraphDiv {
  display: flex;
}

.renewableFlexDiv {
  display: flex;
  flex-grow: 1;
  position: relative;
  .apexcharts-radialbar-hollow {
    display: none;
  }
}

.renewableProjectsDiv {
  background-color: #1f1f1f;
  padding: 16px;
  border-radius: 8px;
  width: 70%;
  height: 257.6px;
  margin-right: 0.8rem;
  display: flex;
  flex-direction: column;
  @media only screen and (min-height: 1200px) {
    height: auto;
  }
}

.procurementDiversityDiv {
  background-color: #1f1f1f;
  padding: 0.8rem 0.8rem 0rem 0.8rem;
  border-radius: 0.5rem;
  height: 257.6px;

  width: 30%;
  @media only screen and (min-height: 1200px) {
    height: auto;
  }
  .apexcharts-datalabels-group {
    display: none;
  }
}

.diversityLabels {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.renewableProjectsHeaderDiv {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  align-items: center;
}

.renewableDiv {
  width: 100%;
  background-color: #1f1f1f;
  padding: 0.8rem;
  border-radius: 0.5rem;
}

.emissionsDiv {
  width: 100%;
  background-color: #1f1f1f;
  padding: 0.8rem;
  border-radius: 0.5rem;
}
.procurementChart {
  flex-grow: 1;
}
.procurementChart .apexcharts-text {
  fill: white;
  font-family: "Montserrat" !important;
  font-weight: 600 !important;
}

.co2Labels {
  display: flex;
  width: 100%;
  @media only screen and (min-height: 1200px) {
    justify-content: center;
  }
}

.offsetButton {
  margin-left: 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #1f1f1f;
  padding: 11px 16px;
  height: 42px;
}

.offsetButton:focus {
  border: none;
  outline: none;
}

.esg_header_button {
  position: relative;
  margin-left: 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #1f1f1f;
  padding: 11px 16px;
  height: 42px;
}

.esg_header_button:focus {
  border: none;
  outline: none;
}

.esg_hover_container {
  display: none;
  position: absolute;
  font-size: 14px;
  top: 60px;
  left: 50%;
  transform: translateX(-44%);
  background-color: #1f1f1f;
  color: white;
  border: 1px solid #ff1da4;
  border-radius: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 5;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 13px 13px 13px;
    border-color: transparent transparent #ff1da4 transparent;
    transform: translateY(-100%) translateX(-50%);
    z-index: 1;
  }

  &::after {
    content: "";
    position: absolute;
    top: 1px;
    left: 50%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 12px 12px;
    border-color: transparent transparent #1f1f1f transparent;
    transform: translateY(-100%) translateX(-50%);
    z-index: 2;
  }
}

.esg_header_button:hover + .esg_hover_container {
  display: block;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 11px;
  }
}

.esg_hover_inner_container {
  background-color: #1f1f1f;
  padding: 8px;
  border-radius: 8px;
  font-size: 16px;
}

.top5esgname {
  font-size: 14px;
  margin: 0;
  color: #f1f1f1;
  position: relative;
}

.top5esg_image_and_name {
  display: flex;
  gap: 10px;
  align-items: center;
  position: relative;
}
.top5esgname_hover {
  position: absolute;
  display: none;
}
// .top5esg_image_and_name:hover + .top5esgname_hover {
//   display: inline-block;
//   position: absolute;
//   bottom: -300px;
//   width: 300px;
//   height: 100px;
//   background-color: red;
//   border-radius: 8px;
//   font-weight: 600;
//   font-size: 14px;
// }
