.switch-button {
  display: flex;
  align-items: center;
  font-family: 'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: white;

.switch-checkbox{
  height: 16px !important;
}
  span {
    cursor: pointer;
    color: #aaa;
    padding: 5px 10px;

    &.active {
      color: white;
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 16px;
    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #313131;
      transition: .4s;

      &:before {
        position: absolute;
        content: "";
        height: 10px;
        width: 10px;
        left: 4px;
        bottom: 2.8px;
        background-color: #FAFAFA;
        transition: .4s;
      }

      &.round {
        border-radius: 34px;

        &:before {
          border-radius: 50%;
        }
      }
    }
  }
}

input:checked + .slider:before {
  transform: translateX(14px);
}
