.column-bar-statistics-div {
  display: flex;
  flex-direction: column;
  height: 95%;
  // height: 100%;
  justify-content: space-between;
  p:nth-of-type(1) {
    &::before {
      background-color: #ff1da4;
    }
  }

  p:nth-of-type(2) {
    &::before {
      background-color: #51da88;
    }
  }

  p:nth-of-type(3) {
    &::before {
      background-color: #41baee;
    }
  }

  .location {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #f1f1f1;
    position: relative;
    margin-left: 15px;
    margin-bottom: 8px;
    @media only screen and (max-width: 1400px) and (min-width: 1280px) {
      font-size: 10px;
    }

    &::before {
      position: absolute;
      content: "";
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background-color: red;
      left: -16px;
      top: 4px;
    }
  }
}
.single_column_bar {
  display: flex;
  flex-direction: column;
  height: 90%;
  // height: 100%;
  justify-content: space-between;
  p:nth-of-type(1) {
    &::before {
      background-color: #ff1da4;
    }
  }

  p:nth-of-type(2) {
    &::before {
      background-color: #51da88;
    }
  }

  p:nth-of-type(3) {
    &::before {
      background-color: #41baee;
    }
  }
  // .apexcharts-tooltip {
  //   display: none;
  // }

  .location {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #f1f1f1;
    position: relative;
    margin-left: 15px;
    margin-bottom: 8px;
    @media only screen and (max-width: 1400px) and (min-width: 1280px) {
      font-size: 10px;
    }

    &::before {
      position: absolute;
      content: "";
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background-color: red;
      left: -16px;
      top: 4px;
    }
  }
}
.single_column_bar_legend {
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
}
