.mainModalDiv {
  padding: 20px;
  display: block;
  background-color: #f1f1f1;
}

.headerText {
  font-weight: 600;
  font-size: 24px;
  color: #1f1f1f;
  margin: 0;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 18px;
  }
}

.closeImg {
  width: 12px;
  height: 12.03px;
}

.headerDiv {
  display: flex;
  justify-content: space-between;
}

.contentHeaderDiv {
  display: flex;
}

.freeDayDiv {
  display: flex;
  width: 100%;
}

.containersHeader {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.containerdetails {
  padding: 0.875rem;
  margin-top: 1rem;
  background-color: #fafafa;
}

.containersHeaderText {
  font-weight: 600;
  font-size: 16px;
  color: #1f1f1f;
  min-width: 300px;
  display: flex;
  flex-direction: row;
  padding: 20px 0 0;
  margin: 0;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 11px;
  }
}

.italicText {
  font-style: italic;
  font-size: 0.875rem;
}

.containersItem {
  background-color: #ffffff;
  padding: 10px;
}

.containersFees {
  display: flex;
  border-bottom: 1px solid rgba(140, 140, 140, 0.4);
}

.containersTotalDiv {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(140, 140, 140, 0.4);
  padding-top: 10px;
}

.containersTotalText {
  font-weight: 600;
  font-size: 14px;
  margin: 0;
}

.portFreeRow {
  display: flex;
  justify-content: space-between;
  height: 25px;
}

.portFreeDayDiv {
  background-color: #fafafa;
  width: 50%;
  padding: 0 0.875rem;
}

.lineFreeDayDiv {
  background-color: #fafafa;
  width: 50%;
  border-left: 1px solid rgba(140, 140, 140, 0.4);
  padding: 0 0.875rem;
}

.containerId {
  font-weight: 600;
  color: #ee4a26;
  margin-left: 1rem;
}

.containerInfoDiv {
  padding: 1rem;
  width: 30%;
  background-color: #fafafa;
  margin-left: 0.875rem;
}

.containerInfoRow {
  display: flex;
  justify-content: space-between;
}

.containerType {
  display: flex;
  flex-direction: column;
  text-align: end;
  padding-top: 5px;
}

.demurrageFees {
  background-color: #fafafa;
  width: 50%;
  padding: 0 16px 10px 0;
}

.detentionFees {
  width: 50%;
  border-left: 1px solid rgba(140, 140, 140, 0.4);
  padding-left: 16px;
}

.labelGray {
  font-weight: 400;
  font-size: 14px;
  color: #8c8c8c;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 10px;
  }
}

.labelBold {
  font-weight: 600;
  font-size: 14px;
  margin: 0;
  color: #1f1f1f;
  text-align: right;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 10px;
  }
}

.labelTime {
  display: flex;
  justify-content: end;
  font-size: 14px;
  font-style: italic;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 10px;
  }
}

.footerDiv {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.totalDemurrageAmonut {
  font-weight: 600;
  font-size: 16px;
  margin-left: 5px;
  color: #ee4a26;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 11px;
  }
}

.containerFeesText {
  font-weight: 600;
  font-size: 14px;
  color: #1f1f1f;
}

.perDiemFees {
  width: 50%;
  border-left: 1px solid rgba(140, 140, 140, 0.4);
  padding-left: 3%;
}

.feesTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
}

.feesTableRow {
  display: flex;
}

.tableDiv {
  width: 25%;
  font-weight: 400;
  font-size: 14px;
  color: #8c8c8c;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 10px;
  }
}

.tableAmountDiv {
  width: 25%;
  font-weight: 400;
  font-size: 14px;
  color: #8c8c8c;
  text-align: right;
}

.tableAmountText {
  text-align: right;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 11px;
  }
}

.tableColumn {
  width: 20%;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 11px;
  }
}

.tableColumnRate {
  width: 20%;
  text-align: right;
  padding-right: 16px;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 11px;
  }
}

.grayLabel {
  color: #8c8c8c;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 11px;
  }
}

.grayLabelTotal {
  color: #8c8c8c;
  text-align: right;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 11px;
  }
}

.containerTotal {
  display: flex;
  justify-content: space-between;
  padding: 0.875rem 0 0;
  background-color: #fafafa;
}

.riskTotal {
  display: flex;
  justify-content: space-between;
  background-color: #fafafa;
  color: #8c8c8c;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 11px;
  }
}

.tableTotalDiv {
  border-top: 1px solid rgba(140, 140, 140, 0.4);
  font-weight: 600;
  text-align: right;
  font-size: 1rem;
  padding-top: 0.875rem;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 11px;
  }
}

.flexDiv {
  display: flex;
}
.info_icon_modal {
  position: relative;
}
.calendar_div {
  position: absolute;
  width: 320px;
  background-color: #f1f1f1;
  display: none;
  right: 30px;
  z-index: 1000;
  border-radius: 8px;
}
.info_icon_modal:hover + .calendar_div {
  display: block;
}

.tariffDot {
  border-radius: 25px;
  width: 8px;
  height: 8px;
}
