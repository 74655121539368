.watch_list_table {
  width: 50%;
  display: flex;
  flex-direction: column;
  height: 800px;
  overflow-y: auto;
  margin-left: 16px;
}

.watch_list_row {
  width: 100%;
  background-color: #1f1f1f;
  display: flex;
  align-items: center;
  border-radius: 8px;
  height: 65px;
  cursor: pointer;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    height: 45px;
  }
  position: relative;
}
.watch_list_row:hover {
  background-color: #090909;
}
.watch_list_row_dark {
  width: 100%;
  background-color: #090909;
  display: flex;
  align-items: center;
  border-radius: 8px;
  height: 65px;
  cursor: pointer;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    height: 45px;
  }
  position: relative;
}
.container_id_watch_list {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  padding-left: 8px;
  width: 18%;
  color: #f1f1f1;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 14px;
  }
}
.watch_list_middle_cell {
  display: flex;
  width: 82%;
}
.watch_list_status_cell {
  width: 75%;
  display: flex;
  align-items: center;
}

.watch_list_dot_cell {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.watch_list_location_cell {
  display: flex;
  align-items: center;
  color: #f1f1f1;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 11px;
  }
}
.watch_list_pay_cell {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.watch_list_bookmark_cell {
  width: 5%;
  display: flex;
  justify-content: flex-end;
  margin-left: 16px;
  padding-right: 16px;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    padding-right: 11px;
  }
}

.yellow_box {
  display: flex;
  align-items: center;
  margin-left: 8px;
  font-size: 14px;
  padding: 8px;
  border-radius: 8px;
  width: 131px;
  color: #cbff00;
  background-color: #cbff0033;
}
.ligth_purple_box {
  display: flex;
  align-items: center;
  margin-left: 8px;
  font-size: 14px;
  padding: 8px;
  border-radius: 8px;
  width: 167px;
  color: #ff00e2;
  background-color: #ff00e233;
}
.ligth_blue_box {
  display: flex;
  align-items: center;
  margin-left: 8px;
  font-size: 14px;
  padding: 8px;
  border-radius: 8px;
  width: 151px;
  color: #00ffff;
  background-color: #00ffff33;
}
.purple_box {
  display: flex;
  align-items: center;
  margin-left: 8px;
  font-size: 14px;
  padding: 8px;
  border-radius: 8px;
  width: 155px;
  color: #bd00ff;
  background-color: #bd00ff33;
}
.dark_blue_box {
  display: flex;
  align-items: center;
  margin-left: 8px;
  font-size: 14px;
  padding: 8px;
  border-radius: 8px;
  width: 99px;

  color: #0055ff;
  background-color: #0055ff33;
}

.green_box {
  display: flex;
  align-items: center;
  margin-left: 8px;
  font-size: 14px;
  padding: 8px;
  border-radius: 8px;
  width: 151px;
  color: #00ff65;
  background-color: #00ff6533;
}
.red_box {
  display: flex;
  align-items: center;
  margin-left: 8px;
  font-size: 14px;
  padding: 8px;
  border-radius: 8px;
  width: 164px;
  color: #ff5400;
  background-color: #ff540033;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 25px;
  margin-right: 8px;
  margin-left: 8px;
}

.hover_status {
  display: none;
  background-color: #1f1f1f;
  border-radius: 8px;
  font-weight: 600;
  position: absolute;
  font-size: 14px;
}
.watch_list_dot_cell:hover + .hover_status {
  top: 10px;
  left: 20%;
  position: absolute;
  display: block;
  border-radius: 8px;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 11px;
  }
}
