.filterBar {
  display: flex;
  height: 50px;
}

.watch_list_button {
  background-color: #1f1f1f;
  color: #8c8c8c;
  height: 42px;
  width: 42px;
  border: none;
  border-radius: 8px;
  margin-left: 16px;
  transition: 0.3s all ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    height: 30px;
    width: 90px;
    font-size: 11px;
  }
}

.watch_list_button:focus {
  outline: none;
}

.watch_list_white_button {
  background-color: #1f1f1f;
  color: #f1f1f1;
  height: 42px;
  width: 42px;
  border: none;
  border-radius: 8px;
  margin-left: 16px;
  transition: 0.3s all ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    height: 30px;
    width: 90px;
    font-size: 11px;
  }
}

.watch_list_white_button:focus {
  outline: none;
}

// .apply_button_inactive:hover {
//   opacity: 0.9;
// }
.apply_button {
  background-color: #ff00b8;
  color: #f1f1f1;
  font-weight: 600;
  border-radius: 8px;
  border: none;
  height: 40px;
  width: 80px;
  margin-left: 16px;
}

.apply_button:disabled {
  opacity: 0.8;
}

.apply_button:focus {
  outline: none;
}

// .apply_button:hover {
//   opacity: 1;
// }
.reset_button {
  background-color: #1f1f1f;
  color: #8c8c8c;
  border: none;
  border-radius: 8px;
  height: 42px;
  width: 42px;
  margin-left: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11px;

  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    height: 30px;
    width: 80px;
    font-size: 11px;
  }
}

.reset_button:focus {
  outline: none;
  border: none;
}

.excel_button {
  background-color: #1f1f1f;
  color: #8c8c8c;
  border: none;
  border-radius: 8px;
  height: 42px;
  width: 42px;
  margin-left: 16px;
  display: flex;
  align-items: center;
  padding: 11px;
  justify-content: center;

  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    height: 30px;
    width: 30px;
  }
}

.excel_button:focus {
  outline: none;
  border: none;
}

.filter_list {
  width: 50%;
  height: 800px;
  margin: 0px 16px;
  overflow: auto;
}

.filter_list_row {
  width: 100%;
  background-color: #1f1f1f;
  border-radius: 8px;
  display: flex;
}

.container_id_filter {
  font-size: 24px;
  font-weight: 600;
  width: 220px;
  color: #f1f1f1;
  height: 65px;
  padding-left: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.bookmark_cell {
  width: 50px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.dot_cell {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.availability_cell {
  font-size: 14px;
  color: #f1f1f1;
  width: 330px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.bookmark_cell_filters {
  width: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.hover_filter {
  display: none;
  position: absolute;
  background-color: #1f1f1f;
  border-radius: 8px;
  border: 1px solid #FF00B8;
  margin-top: 8px;
  z-index: 5;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 13px 13px 13px;
    border-color: transparent transparent #ff1da4 transparent; 
    transform: translateY(-100%) translateX(-50%);
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 1px;
    left: 50%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 12px 12px; 
    border-color: transparent transparent #1f1f1f transparent;
    transform: translateY(-100%) translateX(-50%);
    z-index: 2;
  }
} 

.hover_filter_inner_div {
  color: white;
  padding: 8px;
  border-radius: 8px;
}

.watch_list_button:hover+.hover_filter {
  display: block;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 11px;
  }
}

.watch_list_white_button:hover+.hover_filter {
  display: block;
}

.reset_button:hover+.hover_filter {
  display: block;
}

.excel_button:hover+.hover_filter {
  display: block;
}