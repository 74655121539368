$sunrise_orange: #ee4a26;
$white: white;

html,
body {
  height: 100%;
  font-family: "Montserrat" !important;
}
@media (min-width: 1900px) {
  body {
    zoom: 100%;
  }
}

@media only screen and (max-width: 1900px) and (min-width: 1679px) {
  body {
    zoom: 87%;
  }
}

@media only screen and (max-width: 1679px) and (min-width: 1599px) {
  body {
    zoom: 83%;
  }
}

@media only screen and (max-width: 1599px) and (min-width: 1439px) {
  body {
    zoom: 75%;
  }
}

.custom-calendar {
  background-color: #313131;
  .rdrDayNumber span {
    color: #f1f1f1;
  }
  .rdrDayPassive .rdrDayNumber span {
    color: #8c8c8c;
  }
  .rdrMonthAndYearPickers select {
    color: #8c8c8c;
  }
  .rdrNextPrevButton {
    background-color: #8c8c8c;
  }
  .rdrDayToday .rdrDayNumber span::after {
    background-color: #8c8c8c;
  }
  .rdrDayDisabled {
    background-color: #313131;
    cursor: pointer;
  }
}
// @media only screen and (max-width: 1439px) and (min-width: 1365px) {
//   body {
//     zoom: 71%;
//   }
// }

// @media only screen and (max-width: 1365px) and (min-width: 1279px) {
//   body {
//     zoom: 67%;
//   }
// }

#app {
  height: 100%;
}
a {
  cursor: pointer;
}

.p-14-gray {
  font-size: 0.875rem;
  font-weight: 400;
  color: #8c8c8c;
}

.p-14-white {
  font-size: 0.875rem;
  font-weight: 400;
  color: #fafafa;
}

.p-16-b-white {
  color: #fafafa;
  font-size: 1rem;
  font-weight: 600;
}

.p-16-blue {
  color: #41baee;
  font-size: 1rem;
  font-weight: 400;
}
.p-16-green {
  color: #51DA87;
  font-size: 1rem;
  font-weight: 400;
}
.p-16-purple{
  color: #ff00b8;
  font-size: 1rem;
  font-weight: 400;
}

.p-24-gray {
  color: #8c8c8c;
  font-size: 1.5rem;
  font-weight: 400;
  margin: 0;
}

.p-32-b-white {
  color: #fafafa;
  font-size: 2rem;
  font-weight: 600;
}

.p-48-b-white {
  font-weight: 600;
  font-size: 3rem;
  color: #fafafa;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 2.375rem;
  }
}

.p-24-b {
  font-size: 1.5rem;
  font-weight: 600;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 1.0625rem;
  }
}

.p-24-b-white {
  font-size: 1.5rem;
  font-weight: 600;
  color: #fafafa;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 1.0625rem;
    margin-bottom: 0.5rem;
  }
}

.p-24-white {
  font-size: 1.5rem;
  color: #fafafa;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
}

.p-16-b {
  font-size: 1rem;
  font-weight: 600;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 0.6875rem;
    margin-bottom: 0.5rem;
  }
}

.p-16-gray {
  font-size: 1rem;
  color: #8c8c8c;
  margin: 0;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 0.6875rem;
  }
}

.p-16 {
  font-size: 1rem;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 0.6875rem;
    margin-bottom: 0.5rem;
  }
}

.p-16-white {
  font-size: 1rem;
  color: #fafafa;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 0.6875rem;
    margin-bottom: 0.5rem;
  }
}

.p-14-b {
  font-size: 0.875rem;
  font-weight: 600;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 0.625rem;
    margin-bottom: 0.5rem;
  }
}

.p-14-b-blue {
  font-size: 0.875rem;
  font-weight: 600;
  color: #41baee;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 0.625rem;
    margin-bottom: 0.5rem;
  }
}

.p-14-b-blue:hover {
  color: #ee4a26;
}

.p-14-b-white {
  font-size: 0.875rem;
  font-weight: 600;
  color: #fafafa;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 0.625rem;
    margin-bottom: 0.5rem;
  }
}

.p-14 {
  font-size: 0.875rem;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 0.625rem;
    margin-bottom: 0.5rem;
  }
}

.checkboxDiv .css-i4bv87-MuiSvgIcon-root {
  width: 2rem;
  padding: 0;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    width: 1.2rem;
  }
}

.css-i4bv87-MuiSvgIcon-root {
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    height: 17px !important;
  }
}

.apexcharts-svg {
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    // margin-top: 20px;
  }
}

// .containerZoom {
//   @media only screen and (max-width: 1400px) and (min-width: 1280px) {
//     // zoom: 60%;
//   }
// }

.sidebarIcon {
  margin-right: 10px;
  width: 0.9375rem;
  height: 1rem;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    width: 0.625rem;
    height: 0.6875rem;
  }
}

.selectedLabel {
  border-bottom: 3px solid #ee4a26;
  font-weight: 600;
  margin: 10px;
  font-size: 1rem;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 0.6875rem;
  }
}

.unSelectedLabel {
  color: #8c8c8c;
  margin: 10px;
  font-size: 1rem;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 0.6875rem;
  }
}

.css-15v22id-MuiAccordionDetails-root {
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    padding-top: 0 !important;
  }
}

.sidebarLogo {
  width: 7.0625rem;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    width: 5rem;
  }
}

.sidebarLogoSmall {
  width: 2.1875rem;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    width: 1.563rem;
  }
}

.navigationLabel {
  margin: 0;
  font-weight: 600;
  font-size: 24px;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 1.0625rem;
  }
}

.navigationBackLabel {
  background-color: transparent;
  border: none;
  font-size: 14px;
  margin: 0px 16px;
  font-weight: 600;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    height: 2.125rem;
    width: 2.125rem;
    font-size: 0.625rem;
  }
}

.navigationNotificationIcon {
  margin-right: 1rem;
  height: 2.625rem;
  width: 2.625rem;
  border-radius: 8px;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    height: 1.88rem;
    width: 2.2rem;
  }
}

.navigationProfileButton {
  height: 2.625rem;
  border-radius: 8px;
  margin-right: 1rem;
  width: 200px;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1rem;
  color: #8c8c8c;
  padding-right: 0.6875rem;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    height: 1.88rem;
    font-size: 0.6875rem;
    width: 160px;
  }
}

.inputErrorMsg {
  margin-bottom: 0;
  font-size: 1rem;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 0.6875rem;
  }
}

.inputErrorIcon {
  margin-right: 0.3125rem;
  margin-bottom: 0.15rem;
  width: 1rem;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    width: 0.6875rem;
  }
}

.passwordError {
  color: #da5151;
  margin: 0;
  font-size: 1rem;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 0.6875rem;
  }
}

.p-4 {
  display: flex;
}

.paginationIconArrowLeft {
  margin-right: 26px;
}
.paginationIconArrowLeft:focus {
  outline: none;
}
.paginationIconArrowRight {
  margin-left: 26px;
}
.paginationIconArrowRight:focus {
  outline: none;
}

.container {
  display: flex;
  height: "100%";
}

.admin-nav {
  padding-top: 0;
  padding-bottom: 0;
  background-color: #e8e9ea;
  border-bottom: 1px solid #ccc;
}
.form-control {
  border-radius: inherit;
}

.customerStatisticsActivated {
  width: 25%;
  text-align: center;
  padding-top: 15px;
  border-right: 1px solid rgba(189, 189, 189, 0.4);
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    padding-top: 0px;
  }
}

.customerStatisticsInvited {
  text-align: center;
  padding-top: 30px;
  max-height: 100px;
}

.customerStatisticsRevenue {
  text-align: center;
  padding-top: 40px;
  width: 25%;
  border-left: 1px solid rgba(189, 189, 189, 0.8);
  border-right: 1px solid rgba(189, 189, 189, 0.4);
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    padding-top: 15px;
  }
}

.customerStatisticsMissing {
  width: 25%;
  text-align: center;
  padding-top: 40px;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    padding-top: 15px;
  }
}

.customerStatisticsMainDiv {
  background: white;
  padding-bottom: 20px;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    padding-bottom: 14px;
  }
}

.customerStatisticsLabel {
  color: #8c8c8c;
  margin-top: 10px;
  margin-bottom: 0px;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 0.688rem;
  }
}

.customerStatisticsLabelInvited {
  color: #8c8c8c;
  margin-top: 15px;
  margin-bottom: 0px;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 0.688rem;
    position: absolute;
    left: 385px;
    top: 110px;
  }
}

.customerStatisticsValueGreen {
  font-weight: 600;
  color: #51da88;
  font-size: 24px;
  margin: 0px;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 1.01rem;
  }
}

.customerStatisticsValue {
  font-weight: 600;
  color: #b00303;
  font-size: 24px;
  margin: 0px;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 1.01rem;
  }
}

.need-attention-cell {
  position: relative;
  &::after {
    position: relative;
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: red;
    right: -2px;
    top: -13px;
  }
}

.MuiAutocomplete-hasPopupIcon.css-1qqsdnr-MuiAutocomplete-root
  .MuiOutlinedInput-root,
.MuiAutocomplete-hasClearIcon.css-1qqsdnr-MuiAutocomplete-root
  .MuiOutlinedInput-root {
  border-radius: inherit;
}

.css-kpm0tx-MuiButtonBase-root-MuiButton-root {
  font-family: "Montserrat" !important;
}

.css-sghohy-MuiButtonBase-root-MuiButton-root {
  font-family: "Montserrat" !important;
}

.ag-root ::-webkit-scrollbar {
  width: 0.3rem;
  height: 0.3rem;
}
.ag-root ::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.ag-root ::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 20px;
}
// .ag-root ::-webkit-scrollbar-thumb:hover {
//   background: #555;
// }​

.ag-theme-alpine {
  --ag-font-family: "Montserrat";
  --ag-header-background-color: #eddfce;
  --ag-header-foreground-color: gray;
  --ag-row-hover-color: #f2f2f2;

  .ag-ltr .ag-cell {
    font-weight: 600 !important;
  }

  .ag-row-odd {
    background-color: white;
  }

  .ag-row-even {
    background-color: white;
  }

  // .ag-row-hover {
  //   background-color: #fff2f2f2 !important;
  // }
}

.cell-green {
  color: #094f45;
  white-space: pre !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.cell-blue {
  color: #1288f5;
  white-space: pre !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.cell-red {
  color: #e80403;
  white-space: pre !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.custom-tooltip {
  padding: 10px;
  border: 1px solid cornflowerblue;
  overflow: hidden;
}

.custom-tooltip p {
  margin: 5px;
  white-space: nowrap;
  font-weight: bold;
}

.customer-status {
  position: relative;
}

.customer-tooltip {
  border: 1px solid cornflowerblue;
  visibility: hidden;
  position: absolute;
  z-index: 1;
  width: 130px;
  top: 65%;

  p {
    margin: 5px;
    font-size: 12px;
  }
}

.customer-status:hover .customer-tooltip {
  visibility: visible;
}

.demurrage-status {
  position: relative;
}

.demurrage-status-tooltip {
  border: 1px solid cornflowerblue;
  background-color: white;
  visibility: hidden;
  position: absolute;
  z-index: 1;
  width: 210px;
  top: 90%;
  left: -50px;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  padding: 10px;

  p {
    margin: 0;
  }
}

.toast_styles {
  margin-top: 60px;
  z-index: 1000000;
  font-family: "Montserrat";
  border-radius: 8px;
}
.demurrage-status:hover .demurrage-status-tooltip {
  visibility: visible;
}

.customer-contract {
  position: relative;
}

.customer-contract-tooltip {
  border: 1px solid cornflowerblue;
  background-color: white;
  visibility: hidden;
  position: absolute;
  z-index: 1;
  width: 210px;
  top: 70%;
  left: 30px;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  padding: 10px;

  p {
    margin: 0;
  }
}
.tooltop {
  visibility: hidden;
}
.tooltop-on-hover:hover + .tooltop {
  visibility: visible;
}
.customer-contract:hover .customer-contract-tooltip {
  visibility: visible;
}

.demurrage-mbl {
  position: relative;
}

.demurrage-mbl-tooltip {
  border: 1px solid cornflowerblue;
  background-color: white;
  visibility: hidden;
  position: absolute;
  z-index: 1;
  width: 170px;
  top: -14px;
  left: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  padding: 10px;

  p {
    margin: auto;
  }
}

.demurrage-mbl:hover .demurrage-mbl-tooltip {
  visibility: visible;
}

.need-attention-cell:hover .need-attention-tooltip {
  visibility: visible;
}

.need-attention-tooltip {
  visibility: hidden;
  position: absolute;
  width: 320px;
  z-index: 1;
  padding: 8px;
  background-color: white;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  box-shadow: 2px 2px 5px 0px #1f1f1f26;
  left: 135px;
  top: 20px;

  .title {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: #1f1f1f;
    margin-bottom: 8px;
  }

  .text {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: #8c8c8c;
    margin-bottom: 0;
  }
}

.apexcharts-datalabel-label {
  display: none;
}

.speed-chart {
  position: relative;
  height: 90px;
  margin: 0px !important;

  #apexcharts-radialbarTrack-0 {
    stroke-dasharray: 3;
  }
}

.apexcharts-datalabel-value {
  position: absolute;
}

.dashboard-statistisc-left-middle {
  border-left: 1px solid rgba(189, 189, 189, 0.4);
  border-right: 1px solid rgba(189, 189, 189, 0.4);
}

.progress-bar-tooltip {
  position: absolute;
  top: 0;
  left: 125px;
  width: 320px;
  z-index: 1;
  padding: 8px;
  background-color: white;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  box-shadow: 2px 2px 5px 0px #1f1f1f26;

  .text {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: #8c8c8c;
    margin-bottom: 0;
  }
}

.mcleod-verification {
  position: relative;
}

.mcleod-verification:hover .mcleod-tooltip {
  visibility: visible;
}

.mcleod-tooltip {
  position: absolute;
  visibility: hidden;
  top: -6px;
  left: 40px;
  width: 243px;
  z-index: 1;
  padding: 8px;
  background-color: white;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  box-shadow: 2px 2px 5px 0px #1f1f1f26;
  transition: 0.3ms ease-in-out all;

  .text {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: #8c8c8c;
    margin-bottom: 0;
  }
}

.customer-details {
  position: relative;
}

.customer-details-tooltip {
  border: 1px solid cornflowerblue;
  background-color: white;
  visibility: hidden;
  position: absolute;
  z-index: 1;
  width: 210px;
  font-size: 12px;
  top: 45px;
  left: 37%;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  padding: 10px;

  p {
    margin: 0;
  }
}

.demurage-status-approval {
  position: relative;
}

.demurage-status-approval-tooltip {
  border: 1px solid cornflowerblue;
  background-color: white;
  visibility: hidden;
  position: absolute;
  z-index: 1;
  width: 200px;
  top: 45px;
  left: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  padding: 8px;
  transition: 0.3s ease-in-out all;

  p {
    margin: auto;
  }
}

.demurage-status-approval:hover .demurage-status-approval-tooltip {
  visibility: visible;
}

.customer-details:hover .customer-details-tooltip {
  visibility: visible;
  transition: all 0.4s 2s ease;
}

.no-rows-message {
  font-family: "Montserrat" !important;
}

.grid-cell-centered {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.cell-status {
  padding: 0 33px !important;
}

.ag-header-cell-label {
  justify-content: center;
}

.notification-accordion:hover {
  background-color: #dfd6ca;
}

// .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
//   height: 25px;
// }

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: "Montserrat" !important;
}
// .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
//   height: 40px;
//   background-color: #d6e787;
//   opacity: 50%;
// }

//date and time style
.css-195y93z-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: $sunrise_orange !important;
}
.css-umzx0k-MuiClock-pin {
  background-color: $sunrise_orange !important;
}
.css-rdq5h4-MuiClockPointer-root {
  background-color: $sunrise_orange !important;
}
.css-eg3pzz-MuiClockPointer-thumb {
  background-color: $sunrise_orange !important;
  border: 16px solid $sunrise_orange !important;
}
.css-h2z9v1-MuiButtonBase-root-MuiIconButton-root-MuiClock-amButton {
  background-color: $sunrise_orange !important;
}
.css-rjqbug-MuiButtonBase-root-MuiIconButton-root-MuiClock-pmButton {
  background-color: $sunrise_orange !important;
}

.css-d0vs79-MuiClockPointer-root {
  background-color: $sunrise_orange !important;
}
.css-12t0dn4-MuiClockPointer-thumb {
  border: 16px solid $sunrise_orange !important;
}
.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: $sunrise_orange !important;
}
.custom-form-input {
  height: 45px;
}
.css-1x5jdmq {
  font-family: "Montserrat";
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  font-family: "Montserrat" !important;
}
.css-wb57ya-MuiFormControl-root-MuiTextField-root {
  border-radius: 5px;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.css-1hw9j7s {
  font-family: "Montserrat" !important;
}
.css-v7esy {
  font-family: "Montserrat" !important;
}

.css-1x5jdmq {
  font-family: "Montserrat" !important;
}

.css-b52kj1 {
  font-family: "Montserrat" !important;
}

.css-1ujsas3 {
  font-family: "Montserrat" !important;
}

.css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input {
  height: 21px !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-bottom-right-radius: none !important;
  border-bottom-left-radius: 5px !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: none !important;
}
.customer-autocomplete .css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input {
  padding-top: 0px !important;
}

.demurrage-add-comment {
  &:hover {
    background-color: #1288f5 !important;
  }
}

.table-warning-cell {
  padding: 0 !important;
}

.table-status-cell {
  display: flex;
  justify-content: end;
  align-items: center;
}

.dashboard-accordion .MuiAccordionSummary-content {
  // flex-direction: row-reverse !important;
  margin: 0 16px !important;
}

.customer-list-accordion .MuiAccordionSummary-content {
  margin: 0 16px !important;
}

.customer-list-accordion .MuiAccordionSummary-expandIconWrapper {
  position: relative;
  top: 1px;
  right: 14px;
}

.statuses-expanded {
  &::before {
    content: "";
    position: absolute;
    height: 196px;
    border-left: 1px solid rgba(189, 189, 189, 0.4);
    left: 0px;
    transition: 0.3s ease-in-out all;
  }
}

.statuses-hidden {
  &::before {
    content: "";
    position: absolute;
    height: 26px;
    border-left: 1px solid rgba(189, 189, 189, 0.4);
    left: 0px;
    transition: 0.1s ease-in-out all;
  }
}

.customer-statistics-accordion .MuiAccordionSummary-content {
  justify-content: space-between !important;
  margin: 0 !important;
  min-height: unset !important;
}

.MuiPaper-root.Mui-expanded {
  margin: 0 !important;
}

@media only screen and (max-width: 425px) {
  .p-4 {
    padding: 1rem !important;
  }

  .css-10zdtnx-MuiTypography-root {
    font-size: 12px !important;
    line-height: 14px !important;
  }

  .rows-per-page {
    font-size: 10px !important;
    line-height: 12px !important;
  }

  .css-j204z7-MuiFormControlLabel-root {
    margin-right: 10px !important;
  }

  .customer-contract-tooltip {
    left: -80px;
    width: 180px;
    font-size: 12px;
    padding: 8px;
  }

  .customer-details .css-1q5oif2-MuiInputBase-root-MuiOutlinedInput-root {
    height: 30px;
  }
}

@media only screen and (max-width: 376px) {
  .css-ypiqx9-MuiDialogContent-root {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .css-11vvzpw-MuiButtonBase-root-MuiButton-root {
    width: 35% !important;
    font-size: 0.7rem !important;
  }

  .customer-contract-tooltip {
    left: -110px;
    width: 180px;
    font-size: 12px;
  }

  .customer-details-tooltip {
    left: 22%;
  }
}

@media only screen and (max-width: 321px) {
  .container {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
::-webkit-scrollbar {
  width: 0.3rem;
  height: 0.3rem;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 20px;
}

.pos-rel {
  position: relative;
}

.rdrDefinedRangesWrapper {
  display: none;
}

.rdrDayToday .rdrDayNumber span:after {
  background: black;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
  font-weight: 600;
}

// .rdrSelected,
.rdrInRange {
  opacity: 40%;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-radius: 8px !important;
  border: 1px solid rgba(189, 189, 189, 0.3) !important;
  // border: transparent !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px !important;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 0.625rem !important;
  }
}

.css-1qujxt1-MuiInputBase-root-MuiOutlinedInput-root {
  border: 0px !important;
  border-radius: 8px !important;
}

.css-1qujxt1-MuiInputBase-root-MuiOutlinedInput-root:hover {
  border: 1px solid rgba(238, 74, 38, 0.4) !important;
}

.css-1qujxt1-MuiInputBase-root-MuiOutlinedInput-root:focus-within {
  border: 1px solid rgba(238, 74, 38, 0.9) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}

input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
}

.apexcharts-legend-text {
  color: #fafafa !important;
}
