.main_container {
  width: 100%;
}
.port_header_box {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.left_header_box {
  width: 49%;
  background-color: #1f1f1f;
  border-radius: 8px;
  color: #f1f1f1;
  padding: 16px;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    padding: 11px;
  }
}
.mini_box {
  width: 24%;
  border-radius: 8px;
  background-color: #1f1f1f;
  padding: 16px;
  color: #f1f1f1;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    padding: 11px;
  }
}
.header_title {
  font-weight: 600;
  font-size: 16px;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 11px;
  }
}
.column {
  width: 100%;
  display: flex;
  justify-content: center;
}
.cell {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 16px 0px;
}
.cell_single {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 16px 0px;
}
.value_typography_purple {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  color: #ff00b8;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 18px;
  }
}

.value_typography {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  color: #f1f1f1;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 18px;
  }
}
.key_typography {
  color: #8c8c8c;
  font-size: 14px;
  margin: 0;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 9px;
  }
}

.port_table {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}
.port_table_row {
  width: 100%;
  height: 65px;
  background-color: #1f1f1f;
  border-radius: 8px;
  display: flex;
  transition: 0.3s ease-in-out all;
  cursor: pointer;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    height: 40px;
  }
  position: relative;
}
.port_table_row:hover {
  background-color: #090909;
}
.port_table_row_dark {
  width: 100%;
  height: 65px;
  background-color: #090909;
  border-radius: 8px;
  display: flex;
  cursor: pointer;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    height: 40px;
  }
}
.container_id_terminal {
  font-size: 16px;
  font-weight: 600;
  width: 18%;
  height: 100%;
  color: #f1f1f1;
  padding-left: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media only screen and (max-width: 1000px) {
    font-size: 14px;
  }
  // padding-left: 16px;
}
.dot_cell {
  width: 10%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // position: relative;
}

.availability_cell {
  font-size: 14px;
  color: #f1f1f1;
  width: 210px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 10px;
  }
}
.bookmark_cell {
  width: 5%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 16px;
  margin-left: 16px;
}
.details_cell {
  width: 20%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.pay_cell {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.status_cell {
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.port_grid_title {
  color: #8c8c8c;
  margin: 0;
  font-size: 14px;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 10px;
  }
}
.port_grid_main {
  font-weight: 600;
  color: #f1f1f1;
  margin: 0;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 10px;
  }
  // margin-top: 8px;
}
.port_grid_main_purple {
  font-weight: 600;
  color: #ff00b8;
  margin: 0;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 11px;
  }
  // margin-top: 8px;
}
.port_grid_subtitle {
  color: #f1f1f1;
  margin: 0;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 10px;
  }
  // margin-bottom: 8px;
}
.port_grid_box {
  margin-bottom: 16px;
}
.port_grid_box_top {
  display: flex;
  align-items: center;
}

.container_id {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  margin-left: 8px;
  width: 190px;
}
.yellow_box {
  display: flex;
  align-items: center;
  margin-left: 8px;
  font-size: 14px;
  padding: 8px;
  border-radius: 8px;
  width: 181px;
  color: #d6fc51;
  background-color: #d6fc5133;
}
.dark_purple_box {
  display: flex;
  align-items: center;
  margin-left: 8px;
  font-size: 14px;
  padding: 8px;
  border-radius: 8px;
  width: 160px;
  color: #bd00ff;
  background-color: #bd00ff33;
}
.ligth_blue_box {
  display: flex;
  align-items: center;
  margin-left: 8px;
  font-size: 14px;
  padding: 8px;
  border-radius: 8px;
  width: 126px;
  color: #00ffff;
  background-color: #00ffff33;
}
.purple_box {
  display: flex;
  align-items: center;
  margin-left: 8px;
  font-size: 14px;
  padding: 8px;
  border-radius: 8px;
  width: 140px;
  color: #ea3cdb;
  background-color: #ea3cdb33;
}
.dark_blue_box {
  display: flex;
  align-items: center;
  margin-left: 8px;
  font-size: 14px;
  padding: 8px;
  border-radius: 8px;
  width: 99px;

  color: #0055ff;
  background-color: #0055ff33;
}

.green_box {
  display: flex;
  align-items: center;
  margin-left: 8px;
  font-size: 14px;
  padding: 8px;
  border-radius: 8px;
  width: 180px;
  color: #75fa79;
  background-color: #75fa7933;
}
.orange_box {
  display: flex;
  align-items: center;
  margin-left: 8px;
  font-size: 14px;
  padding: 8px;
  border-radius: 8px;
  width: 170px;
  color: #ed602a;
  background-color: #ed602a33;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 25px;
  margin-right: 8px;
  margin-left: 8px;
}
.hover_status {
  display: none;
  border-radius: 8px;
  font-weight: 600;
  position: absolute;
  font-size: 14px;
}
.dot_cell:hover + .hover_status {
  top: 10px;
  left: 20%;
  position: absolute;
  display: block;
  background-color: #1f1f1f;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 11px;
  }
}

#co2_main_box {
  position: relative;
  display: flex;
  padding-left: 24px;
}
#co2_hover_box {
  display: none;
  
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0; 
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 22px 22px 22px 0;
    border-color: transparent #FF00B8 transparent transparent;
    transform: translateY(-50%) translateX(-100%);
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 20px 20px 0;
    border-color: transparent #1f1f1f transparent transparent;
    transform: translateY(-50%) translateX(-100%);
    z-index: 1;
  }
}
.co2_details_icon:hover + #co2_hover_box {
  display: block;
  position: absolute;
  left: 100%; 
  top: 50%;
  transform: translateY(-50%);
  width: 175px;
  background-color: #1f1f1f;
  border-radius: 8px;
  border: 1px solid #FF00B8;
  padding: 16px;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 11px;
  }
}
.co2_title {
  color: #f1f1f1;
  font-weight: 600;
  margin: 0;
}

.tracking_view_title {
  font-weight: 600;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.tracking_view_title:hover {
  overflow: visible;
  white-space: inherit;
}
