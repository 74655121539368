.document_button {
  width: 100%;
  outline: none;
  border: 1px dashed #8c8c8c;

  color: #51b1da;
  border-radius: 8px;
  transition: 0.3s ease-in-out all;
  margin-right: 10px;
  background-color: #fafafa;
  font-weight: 600;
}
.document_button:hover {
  background-color: #f1f1f1;
}
.document_button:focus {
  outline: none;
}
.document_button:disabled {
  opacity: 0.5;
  background-color: transparent;
}

.document_button_error {
  width: 100%;
  outline: none;
  border: 2px dashed #da5151;

  color: #51b1da;
  border-radius: 8px;
  transition: 0.3s ease-in-out all;
  margin-right: 10px;
  background-color: #fafafa;
  font-weight: 600;
}
.document_button_error:focus {
  outline: none;
  border: 2px dashed #da5151;
}
.gi_row {
  display: flex;
  justify-content: space-between;
  // padding: 8px 0;
  width: 73%;
}
.gi_row_table {
  display: flex;
  justify-content: space-between;
}
.location_outer_div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.location_inner_div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}
.address_container {
  text-align: left;
  transition: 0.3s ease-in-out all;
}
.display_container {
  height: 80px;
  display: flex;
  justify-content: flex-start;
}

.file_name {
  margin: 0;
  font-weight: 600;
  margin-right: 15px;
  margin-left: 10px;
  font-size: 14px;
  outline: none;
  border: none;
  background-color: transparent;
  color: #51b1da;
}
.file_name:focus {
  border: none;
  outline: none;
}
.document_name_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
  width: 100%;
  background-color: #51b1da1a;
  padding: 8px;
  border-radius: 8px;
  height: 47px;
}
.document_date {
  font-size: 14px;
  margin-top: 8px;
  color: #8c8c8c;
}

.container_info_table {
  width: 100%;
  text-align: left;
  margin-top: 8px;
}
.container_info_row {
  border-top: 1px solid #bdbdbd;
  border-bottom: 1px solid #bdbdbd;
  width: 100%;
}

.send_message_container {
  width: 100%;
  height: 58px;
  border: 1px solid #bdbdbd66;
  border-radius: 8px;
  margin-top: 8px;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    height: 41px;
  }
}
.comment_input {
  width: 89%;
  height: 100%;
  border: none;
  outline: none;
  padding-left: 8px;
  background-color: #fafafa;
  border: transparent;
  border-radius: 8px;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    height: 30px;
    font-size: 11px;
  }
}
.send_message_button {
  outline: none;
  border: none;
  border-left: 1px solid #bdbdbd66;
  height: 42px;
  width: 11%;
  margin: 5px 0px;
  background-color: transparent;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    height: 41px;
    margin: 0;
  }
}
.send_message_button:focus {
  outline: none;
}
.messages_box {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  margin-top: 8px;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    height: 150px;
  }
}
.message_rigth {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row-reverse;
  margin-bottom: 8px;
}
.message_left {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 8px;
}
.message_box {
  background-color: #f1f1f1;
  width: 70%;
  padding: 8px;
  border-radius: 8px;
}
.message_text {
  font-size: 14px;
  margin: 0;
  margin-bottom: 5px;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 10px;
  }
}
.comment_date {
  font-size: 14px;
  margin: 0;
  color: #8c8c8c;
  text-align: right;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 10px;
  }
}
.comment_user_name {
  font-size: 14px;
  margin: 0;
  font-weight: 600;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 10px;
  }
}
