.container_statistics_values {
  display: flex;
  flex-direction: column;
  color: #fafafa;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 12px;

  @media only screen and (min-width: 2000px) {
    width: 80%;
  }

  .label {
    color: #f1f1f1;
    position: relative;
    display: flex;
    @media only screen and (max-width: 1400px) and (min-width: 1280px) {
      font-size: 11px;
      left: -16px;
      width: 120px;
    }
    @media only screen and (min-width: 2000px) {
      width: 100%;
      justify-content: space-between;
    }

    p {
      @media only screen and (max-width: 1400px) and (min-width: 1280px) {
        margin-bottom: 11px;
      }
    }

    &::before {
      position: absolute;
      content: "";
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background-color: red;
      left: -16px;
      top: 4px;
    }
  }

  .value {
    color: #8c8c8c;
    margin-bottom: 4px;
    margin-left: 8px;
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  div:nth-of-type(1) {
    &::before {
      background-color: #ea3cdb;
    }
  }

  div:nth-of-type(2) {
    &::before {
      background-color: #d6fc51;
    }
  }

  div:nth-of-type(3) {
    &::before {
      background-color: #00ffff;
    }
  }

  div:nth-of-type(4) {
    &::before {
      background: #0055ff;
    }
  }

  div:nth-of-type(5) {
    &::before {
      background-color: #bd00ff;
    }
  }

  div:nth-of-type(6) {
    &::before {
      background-color: #ed602a;
    }
  }

  div:nth-of-type(7) {
    &::before {
      background-color: #75fa79;
    }
  }
}
.single_statistic_label {
  border-radius: 25px;
  width: 8px;
  height: 8px;
  margin-right: 8px;
}
.radial_bar_legend_box {
  width: 100%;
}
.radial_bar_legend_value {
  margin-bottom: 12px;
}
