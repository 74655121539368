.successfull_payment_main_div {
  width: 100%;
  padding-bottom: 24px;
}
.request_div {
  width: 100%;
  background-color: #1f1f1f;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  margin-bottom: 24px;
}
.request_images_div {
  display: flex;
  gap: 36px;
  align-items: center;
  margin-bottom: 48px;
}
.request_line {
  width: 92px;
  height: 1px;
}
.request_circle {
  width: 112px;
  height: 112px;
  border-radius: 50%;
  background-color: #51da880d;
  display: flex;
  justify-content: center;
  align-items: center;
}
.explore_button {
  all: unset;
  color: #f1f1f1;
  background-color: #ff00b8;
  border-radius: 8px;
  padding: 11px 16px;
  font-weight: 600;
}
.request_summary_div {
  width: 100%;
  border-radius: 8px;
  background-color: #1f1f1f;
  padding: 24px;
}
.pending_transaction_div {
  width: 100%;
  padding: 12px 0px;
  display: flex;
  justify-content: center;
  background-color: #f5af4533;
  border-radius: 8px;
  margin-bottom: 24px;
  margin-top: 24px;
}
.pending_transaction_text {
  font-weight: 600;
  color: #f5af45;
  margin: 0;
}

.hide_show_stats_div {
  width: 100%;
  padding: 11px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #313131;
  border-radius: 8px;
}
