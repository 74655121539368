.donut_chart_div {
  width: 100%;
  position: relative;
  #apexcharts-radialbarTrack-0 {
    stroke-dasharray: 3;
  }
  #apexcharts-donutbarTrack-0 {
    stroke-dasharray: 3;
  }
  .apexcharts-datalabel-value {
    // fill: #f1f1f1 !important;
    font-size: 22px;
    offset: -10;
    display: inline;
  }
  .apexcharts-datalabel-label {
    display: inline;
  }
}
.donut_chart_main_box {
  width: 100%;
  height: 100%;
}
.donut_chart_box {
  height: 100%;
  width: 100%;
  @media screen and (min-width: 2000px) {
    margin-top: 16px;
  }
}
.donut_chart_legend_box {
  // position: absolute;
  display: flex;
  justify-content: space-around;
  color: #f1f1f1;
  width: 100%;
  @media screen and (min-height: 500px) and (max-height: 900px) {
    bottom: 0%;
  }
  @media screen and (min-width: 2000px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
    bottom: 0;
  }
}
