.gauge-chart-div {
  width: 100%;

  #apexcharts-radialbarTrack-0 {
    stroke-dasharray: 3;
  }
  #apexcharts-donutbarTrack-0 {
    stroke-dasharray: 3;
  }
}
.flex_box_legend {
  display: flex;
  align-items: center;
  cursor: pointer;
  @media screen and (min-width: 2000px) {
    width: 80%;
    justify-content: space-between;
  }
}
.gauge_bar_legend_label {
  font-size: 14px;
  margin: 0;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 10px;
  }
}
.gauge_bar_legend_value {
  margin: 0;
  font-size: 14px;
  color: #8c8c8c;
  margin-left: 4px;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 10px;
  }
}
.gauge_bar_legend_circle {
  border-radius: 25px;
  height: 8px;
  width: 8px;
  margin-right: 4px;
}
