.titleModalText {
  font-weight: 600;
  font-size: 24px;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 17px;
  }
}

.modalText {
  font-weight: 400;
  font-size: 16px;
  color: #1f1f1f;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 11px;
  }
}

.modalTextSubmitDemurrage {
  font-weight: 600;
  font-size: 16px;
  color: #ee4a26;
  padding: 10px 0;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 11px;
  }
}

.modalDivHeader {
  display: flex;
  justify-content: space-between;
}

.modalMainDiv {
  padding: 20px;
  min-width: 400px;
}

.closeModalImg {
  width: 12px;
  height: 12px;
  margin-top: 10px;
}

.buttonsDiv {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}

.demurrageApproveModalDiv {
  background: rgba(81, 218, 136, 0.2);
  border-radius: 8px;
  padding: 10px;
}

.demurrageRejectedModalDiv {
  background: rgba(176, 3, 3, 0.1);
  border-radius: 8px;
  padding: 10px;
}

.demurrageIdModal {
  padding-left: 30px;
}

.succesApprovalModalMessage {
  color: #51da88;
  font-weight: 600;
  font-size: 16px;
}

.rejectedApprocalModalMessage {
  color: #b00303;
  font-weight: 600;
  font-size: 16px;
}

.successIcon {
  margin-right: 10px;
}
