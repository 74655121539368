.loginDiv {
  width: 90%;
  overflow-y: auto;
}

.loginText {
  font-weight: 600;
  font-size: 24px;
  color: #fafafa;
}

.loginTextSmall {
  font-weight: 400;
  font-size: 16px;
  color: #8c8c8c;
  margin-left: 5%;
  width: 90%;
}

.text {
  font-weight: 600;
  font-size: 14px;
  color: #fafafa;
}

.forgotTextDiv {
  text-align: initial;
}

.forgotText {
  color: #41baee;
  font-weight: 600;
  font-size: 14px;
}

.forgotText:hover {
  color: #ee4a26;
}

.registerText {
  color: #8c8c8c;
  font-weight: 400;
  font-size: 16px;
  width: 90%;
  margin-left: 5%;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    margin-bottom: 0.5rem;
    font-size: 0.6875rem;
  }
}

.visiableIcon {
  color: #ee4a26;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    width: 1.1rem !important;
  }
}

.checkboxDiv {
  color: #fafafa;
  font-weight: 400;
  font-size: 14px;
  padding-left: 0;
  display: flex;
  text-align: initial;
  margin-top: 10px;
  margin-left: 5%;
  width: 90%;

  .css-i4bv87-MuiSvgIcon-root {
    color: #ee4a26;
  }
  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 20px;
  }
}

.checkboxDivError {
  color: #ee4a26;
  font-weight: 400;
  font-size: 14px;
  padding-left: 0;
  display: flex;
  text-align: initial;
  margin-top: 10px;
  margin-left: 5%;
  width: 90%;

  .css-i4bv87-MuiSvgIcon-root {
    color: #ee4a26;
  }
  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 20px;
  }
}

.checkboxText {
  margin-top: 3px;
}

.inputErrorDiv {
  display: flex;
  justify-content: center;
}
