// color
.c-orange {
  color: #ee4a26;
}
.c-dark-grey {
  color: #8c8c8c;
}
.c-white {
  color: #f1f1f1;
}
.c-black {
  color: #1f1f1f;
}
.c-purple {
  color: #ff00b8;
}
.c-blue {
  color: #41baee;
}
.c-green {
  color: #51da88;
}
// background color
.bc-orange {
  background-color: #ee4a26;
}
.bc-dark-grey {
  background-color: #8c8c8c;
}
.bc-white {
  background-color: #f1f1f1;
}
.bc-black {
  background-color: #1f1f1f;
}
.bc-transparent {
  background-color: transparent;
}
.br8 {
  border-radius: 8px;
}
// display
.d-grid {
  display: grid;
}
.d-block {
  display: block;
}
.d-flex {
  display: flex;
}
// flex

.f-dir-col {
  flex-direction: column;
}
.align-center {
  align-items: center;
}
.align-end {
  align-items: flex-start;
}
.align-start {
  align-items: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-evenly {
  justify-content: space-evenly;
}
.justify-end {
  justify-content: end;
}
.flex1 {
  flex: 1;
}
.f-grow1 {
  flex-grow: 1;
}
f-wrap {
  flex-wrap: wrap;
}
.gap120{
  gap: 120px;
}
.gap24{
  gap: 24px;
}
.gap16 {
  gap: 16px;
}
.gap8 {
  gap: 8px;
}
.gap6 {
  gap: 6px;
}
.gap4 {
  gap: 4px;
}
.gap2{
  gap: 2px;
}
.gap1{
  gap: 1px;
}

// margins and padding

.p8 {
  padding: 8px;
}
.p16 {
  padding: 16px;
}
.pl16 {
  padding-left: 16px;
}
.pr16 {
  padding-right: 16px;
}
.pt16 {
  padding-top: 16px;
}
.pb16 {
  padding-bottom: 16px;
}
.ml16 {
  margin-left: 16px;
}
.mr16 {
  margin-left: 16px;
}
.mt16 {
  margin-top: 16px;
}
.mt24 {
  margin-top: 24px;
}
.mb24{
  margin-bottom: 24px;
}
.mb16 {
  margin-bottom: 16px;
}
.mb8 {
  margin-bottom: 8px;
}
.m0 {
  margin: 0;
}
.w100 {
  width: 100%;
}
.w75 {
  width: 75%;
}
.w50 {
  width: 50%;
}
.w33 {
  width: 33%;
}
.w25 {
  width: 25%;
}
.h100 {
  height: 100%;
}
.h90 {
  height: 90%;
}
.mw1920 {
  max-width: 1920px;
}
// paragraph

.fw600 {
  font-weight: 600;
}
.fs24 {
  font-size: 24px;
}
.fs14 {
  font-size: 14px;
}
.fs10 {
  font-size: 10px;
}
.fs32 {
  font-size: 32px;
}
.fs22 {
  font-size: 22px;
}

.p-relative {
  position: relative;
}
.p-absolute {
  position: absolute;
}
.t-align-center {
  text-align: center;
}

.t-align-left {
  text-align: left;
}
.t-align-right {
  text-align: right;
}
.template_box {
  border-radius: 8px;
  padding: 16px;
  background-color: #1f1f1f;
}

//animations

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
