.outer_circle {
  width: 68px;
  height: 68px;
  border-radius: 50px;
  // background-color: #ff00b81a;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s all ease-in-out;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    width: 45px;
    height: 45px;
  }
}

.middle_circle {
  width: 52px;
  height: 52px;
  border-radius: 50px;
  // background-color: #ff00b84d;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s all ease-in-out;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    width: 35px;
    height: 35px;
  }
}

.inner_circle {
  width: 36px;
  height: 36px;
  border-radius: 50px;
  // background-color: #ff00b880;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s all ease-in-out;
  color: #f1f1f1;
  font-size: 12px;
  font-weight: 700;
  font-family: Montserrat;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    width: 25px;
    height: 25px;
    font-size: 10px;
  }
}
.inner_circle:hover {
  width: 50px;
  height: 50px;
  font-size: 15px;
  // background-color: #ff00b8;
}
.inner_circle_only {
  width: 36px;
  height: 36px;
  border-radius: 50px;
  // background-color: #ff00b880;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s all ease-in-out;
  color: #f1f1f1;
  font-size: 12px;
  font-weight: 700;
  font-family: Montserrat;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    width: 25px;
    height: 25px;
    font-size: 10px;
  }
}
.inner_circle_only:hover {
  width: 50px;
  height: 50px;
  font-size: 15px;
  transform: translateX(-7px) translateY(-7px);
  background-color: #ff00b8;
}
.inner_circle_blue_only {
  width: 36px;
  height: 36px;
  border-radius: 50px;
  background-color: #2372e9;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s all ease-in-out;
  color: #f1f1f1;
  font-size: 12px;
  font-weight: 700;
  font-family: Montserrat;
}
.inner_circle_blue_only:hover {
  width: 50px;
  height: 50px;
  font-size: 18px;
  transform: translateX(-7px) translateY(-7px);
  background-color: #2372e9;
}

.location_button {
  background-color: transparent;
  border: none;
  outline: none;
}
.location_button:focus {
  border: none;
  outline: none;
}

.hover_container {
  display: none;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 12px 0 12px;
    border-color: #ff1da4 transparent  transparent transparent;
    transform: translateY(100%) translateX(-50%);
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #1f1f1f transparent  transparent transparent;
    transform: translateY(100%) translateX(-50%);
    z-index: 1;
  }
}
.location_button:hover + .hover_container {
  border: 1px solid #ff1da4;
  background-color: #1f1f1f;
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translateX(-54%);
  color: white;
  font-size: 24px;
  font-weight: 600;
  border-radius: 8px;
  font-family: montserrat;
  display: flex;
  align-items: center;
  padding: 8px;
  z-index: 9999;
  white-space: nowrap; 
  text-overflow: ellipsis;
}
.location_name {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  margin-left: 8px;
}
