.mainModalDiv_dark {
  width: 100%;
  padding: 16px;
  background-color: #313131;
}

.headerText_dark {
  font-weight: 600;
  font-size: 24px;
  color: #f1f1f1;
  margin: 0;
}

.closeImg {
  width: 12px;
  height: 12.03px;
}

.headerDiv_dark {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.contentDiv_dark {
}

.freeDayDiv_dark {
  display: flex;
  //   justify-content: space-between;
  width: 70%;
  background-color: #1f1f1f;
  border-radius: 8px;
  padding: 16px 0px;
}

.containersHeader_dark {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.containersHeaderText_dark {
  font-weight: 600;
  font-size: 16px;
  color: #1f1f1f;
}

.containersItem_dark {
  background-color: #1f1f1f;
  padding: 10px 10px 0 10px;
  border-radius: 8px 8px 0px 0px;
  border-bottom: 1px solid #8c8c8c66;
}

.containersFees_dark {
  display: flex;
  justify-content: space-between;
  // margin: 10px 0;
}

.containersTotalDiv_dark {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #1f1f1f;
  border-radius: 0px 0px 8px 8px;
}

.containersTotalText_dark {
  font-weight: 600;
  font-size: 14px;
  color: #f1f1f1;
  margin: 0;
}

.portFreeRow_dark {
  display: flex;
  justify-content: space-between;
  padding-right: 16px;
  height: 25px;
}

.portFreeDayDiv_dark {
  background-color: transparent;
  width: 50%;
  padding-left: 16px;
}

.lineFreeDayDiv_dark {
  background-color: transparent;
  width: 50%;
  border-left: 1px solid rgba(140, 140, 140, 0.4);
  padding-left: 3%;
}

.containerId_dark {
  font-weight: 600;
  font-size: 14px;
  color: #ee4a26;
}

.containerIdDiv_dark {
  background-color: white;
  display: flex;
  padding: 10px;
}

.labelGray_dark {
  font-weight: 400;
  font-size: 14px;
  color: #8c8c8c;
}

.labelBold_dark {
  text-align: right;
  font-weight: 600;
  font-size: 14px;
  color: #f1f1f1;
}

.labelTime_dark {
  display: flex;
  justify-content: end;
  padding-right: 5%;
  font-size: 14px;
  color: #f1f1f1;
  font-style: italic;
}

.footerDiv_dark {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  width: 100%;
}

.totalDemurrageAmonut_dark {
  font-weight: 600;
  font-size: 16px;
  color: #ee4a26;
}

.containerFeesText_dark {
  font-weight: 600;
  font-size: 14px;
  color: #f1f1f1;
}

.demurrageFees_dark {
  width: 50%;
  padding: 0 16px 10px 0;
}

.perDiemFees_dark {
  width: 50%;
  border-left: 1px solid rgba(140, 140, 140, 0.4);
  padding-left: 16px;
}

.feesTable_dark {
  width: 100%;
  color: #f1f1f1;
  border-collapse: separate;
  border-spacing: 0 10px;
}

.feesTableRow_dark {
  display: flex;
}

.tableDiv_dark {
  font-weight: 400;
  font-size: 14px;
  color: #8c8c8c;
}

.tableTotalDiv_dark {
  font-weight: 600;
  // padding-right: 10px;
}
.tariffDot {
  border-radius: 25px;
  width: 8px;
  height: 8px;
}
.total_value_div {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
}
.info_icon_modal {
  position: relative;
}
.calendar_div {
  position: absolute;
  width: 320px;
  background-color: #1f1f1f;
  display: none;
  right: 30px;
  z-index: 1000;
  border-radius: 8px;
}
.info_icon_modal:hover + .calendar_div {
  display: block;
}
