.mainDiv {
  display: flex;
  width: 100%;
  position: absolute;
  height: 100%;
}

.cdlLogo {
  margin-top: 10%;
  margin-bottom: 15%;
  width: 12.8rem;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    width: 7rem;
  }
}

.backgroundTextIcon {
  padding-top: 8%;
  width: 60%;
  @media only screen and (max-width: 600px) {
    padding-top: 0;
  }
}

.sidebardiv {
  width: 500px;
  height: 100%;
  background: rgba(31, 31, 31, 0.75);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 0px 25px rgba(52, 52, 52, 0.25);
  backdrop-filter: blur(7.5px);
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}

.rightDiv {
  width: 75%;
  height: 100%;
  text-align: center;
  padding-top: 3%;
  background-color: rgba(50, 0, 0, 0.5);
  padding: 16px;
}

.headerTextLarge {
  font-weight: 600;
  font-size: 48px;
  color: #fafafa;
}

.headerTextSmall {
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #fafafa;
}

.copyright {
  font-weight: 400;
  font-size: 14px;
  color: #8c8c8c;
  margin: 0;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 0.625rem;
  }
}

.copyrightDiv {
  margin: 2% 0;
}
