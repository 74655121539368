.invoice_message_container {
  width: 100%;
  display: flex;
  align-items: center;
  height: 52px;
  border-radius: 8px;
  border: 1px solid #dab351;
  margin-bottom: 16px;
  background-color: #fafafa;
}
.due_date_box {
  display: flex;
  margin-left: 16px;
  align-items: center;
}
.invoice_header {
  color: #dab351;
  font-weight: 600;
  margin: 0;
  margin-left: 8px;
}
.days_left_box {
  display: flex;
  align-items: center;
  margin-left: 70px;
}
.invoice_data {
  margin: 0;
  margin-left: 16px;
}
.rejected_box {
  height: 52px;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 8px 8px 0px 0px;
  background-color: #da51511a;
}
.rejected_box_header {
  color: #da5151;
  font-weight: 600;
  margin: 0;
  margin-left: 8px;
}
.rejected_reason_box {
  background-color: #fafafa;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 0px 0px 8px 8px;
}
.reason_header {
  color: #8c8c8c;
  font-weight: 600;
  margin: 0;
  margin-bottom: 8px;
}
.reason {
  margin: 0;
}
.rejected_icon {
  width: 16px;
  height: 16px;
  margin-left: 16px;
}
