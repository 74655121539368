.scheduling_main_div {
  border-radius: 8px;
  height: 400px;
  width: 100%;
  display: flex;
  //   background-color: #1f1f1f;
}
.date_grid_div {
  width: 70%;
  margin-right: 16px;
  background-color: #1f1f1f;
  height: 100%;
  border-radius: 8px;
  padding: 16px;
}

.appointment_div {
  width: 30%;
  background-color: #1f1f1f;
  height: 100%;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.scheduling_header_div {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: auto;
}
.scheduling_header_title {
  margin: 0;
  transition: 0.3s ease-in-out all;
}
.scheduling_button_div {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.scheduling_button {
  padding: 11px 16px;
  background-color: #ff1da433;
  border: none;
  border-radius: 8px;
  color: #ff1da4;
  font-weight: 600;
  transition: 0.3s ease-in-out all;
}
.loading:after {
  content: " .";
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    color: navy(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: #313131;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 #ff00b8, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 #ff00b8, 0.5em 0 0 #313131;
  }
}
