.notifications_div {
  display: flex;
}
.notification_inner_div{
  position: relative;
}
.notification_button {
  width: 42px;
  height: 42px;
  background-color: transparent;
  border: none;
  margin-left: 16px;
  border-radius: 8px;
  transition: 0.3s all ease-in-out;
  position: relative;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    height: 30px;
    width: 30px;
  }
}
.notification_button:hover {
  background-color: #343434;
}
.notification_button:focus {
  outline: none;
}
.notification_button_active {
  width: 42px;
  height: 42px;
  background-color: #464646;
  border: none;
  margin-left: 16px;
  border-radius: 8px;
  transition: 0.3s all ease-in-out;
  position: relative;
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    height: 30px;
    width: 30px;
  }
}
.notification_button_active:focus {
  outline: none;
}

.hover_notifications {
  display: none;
  position: absolute;
  top: 55px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #1f1f1f;
  color: white;
  border-radius: 8px;
  border: 1px solid #FF00B8;
  white-space: nowrap; 
  text-overflow: ellipsis;
  z-index: 5;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 13px 13px 13px;
    border-color: transparent transparent #ff1da4 transparent; 
    transform: translateY(-100%) translateX(-50%);
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 1px;
    left: 50%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 12px 12px; 
    border-color: transparent transparent #1f1f1f transparent;
    transform: translateY(-100%) translateX(-50%);
    z-index: 2;
  }
}
.notification_button:hover + .hover_notifications {
  display: block;
  
  @media only screen and (max-width: 1400px) and (min-width: 1280px) {
    font-size: 11px;
  }
}
.hover_notification_inner_div {
  background-color: #1f1f1f;
  padding: 8px;
  border-radius: 8px;
}
