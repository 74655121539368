.line-indicator li{

    width: 60px;
    height: 5px;
    background-color: #dedddc;
    border-radius: 5px;
  }
  .line-indicator li:hover{
    background-color: gray;
  }
  .line-indicator li.slick-active{
    background-color: #ee4a26;
  }