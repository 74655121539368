.cart_main_div {
  width: 100%;
  background-color: #1f1f1f;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
}
.cart_button_box {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 24px;
}
.cart_button {
  all: unset;
  background-color: #ff00b8;
  color: #f1f1f1;
  border-radius: 8px;
  font-weight: 600;
  padding: 11px 16px;
}
.cart_payment_main_div {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
  width: 100%;
}
.cart_table_div {
  background-color: #313131;
  width: 65%;
  border-radius: 8px;
}
.cart_payment_div {
  width: 35%;
}
#cart_rigth_div {
  background-color: #313131;
  border-radius: 8px;

  padding: 16px;
}
.cart_header_box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.cart_header {
  margin: 0;
  color: #f1f1f1;
  font-weight: 600;
}
.promo_code_box {
  width: 100%;
  margin-bottom: 24px;
}
.promo_code_flex_box {
  display: flex;
  width: 100%;
}
.promo_code_button {
  all: unset;
  padding: 11px 16px;
  background-color: #4c193e;
  color: #ff00b8;
  font-weight: 600;
  font-size: 16px;
  border-radius: 8px;
  flex-grow: 1;
  justify-content: center;
  display: flex;
}

.promo_code_header {
  color: #8c8c8c;
  margin: 0;
  font-size: 14px;
  font-weight: 600;
}

.cart_summary_div {
  width: 100%;
  border-bottom: 1px solid #8c8c8c66;
  margin-bottom: 16px;
}
.cart_summary_row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.cart_total_box {
  display: flex;
  justify-content: space-between;
}
.cart_suggested_div {
  padding: 16px;
  width: 100%;
  border-radius: 8px;
  width: 100%;
}
.cart_suggested_header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 16px;
}
.cart_suggested_main_div {
  display: inline-flex;
  max-width: 100%;
  gap: 16px;
}
